import { useAppSelector } from "../../effects/useAppSelector";
import { SubscriptionFeature } from "../../types/billing/SubscriptionFeature";
import { moneyUtils } from "../../utils/money.utils";
import { SubscribeLink } from "../access/BlockedFeatureText";
import { RequiredFeature } from "../access/RequiredFeature";
import { SummaryRow } from "../common/summary-row/SummaryRow";
import { SummaryRowGroup } from "../common/summary-row/SummaryRowGroup";
import { SummaryRowItem } from "../common/summary-row/SummaryRowItem";

export function SellerBuysideSummaryRow() {
    const totalClasses = useAppSelector(s => s.sellerBuyside.totalRecordNumber);
    const summary = useAppSelector(s => s.sellerBuyside.summary);

    const getVolumeValues = () => {
        const usd = summary?.usdVolume ? moneyUtils.amountToMM(summary.usdVolume, true) : null;
        const eur = summary?.euroVolume ? moneyUtils.amountToMM(summary.euroVolume, true) : null;

        return [usd, eur];
    }

    return (
        <SummaryRow>
            <RequiredFeature
                inline
                feature={SubscriptionFeature.BwicMonitorCanViewSummaryRow}
                text={<><SubscribeLink text="Subscribe to the Pro plan" />&nbsp;to see BWICs stats.</>}
            >
                <SummaryRowGroup groupName="Securities">
                    <SummaryRowItem label="Count" tooltip="Total number of bonds." value={totalClasses?.toLocaleString()} />
                    <SummaryRowItem label="Volume $/€" tooltip="Total volume of USD/EUR bonds." values={getVolumeValues()} />
                    <SummaryRowItem label="Bids" tooltip="Number of bids." value={summary?.totalBids?.toLocaleString()} />
                    <SummaryRowItem label="Traded" tooltip="Number of trades." value={summary?.totalTraded?.toLocaleString()} />
                    <SummaryRowItem label="AAA" tooltip="Number of bonds with AAA rating." value={summary?.aaa?.toLocaleString()} />
                    <SummaryRowItem label="AA" tooltip="Number of bonds with AA rating." value={summary?.aa?.toLocaleString()} />
                    <SummaryRowItem label="A" tooltip="Number of bonds with A rating." value={summary?.a?.toLocaleString()} />
                    <SummaryRowItem label="BBB" tooltip="Number of bonds with BBB rating." value={summary?.bbb?.toLocaleString()} />
                    <SummaryRowItem label="BB" tooltip="Number of bonds with BB rating." value={summary?.bb?.toLocaleString()} />
                    <SummaryRowItem label="B" tooltip="Number of bonds with B rating." value={summary?.b?.toLocaleString()} />
                    <SummaryRowItem label="CCC" tooltip="Number of bonds with CCC rating." value={summary?.ccc?.toLocaleString()} />
                    <SummaryRowItem label="D" tooltip="Number of bonds with D rating." value={summary?.d?.toLocaleString()} />
                    <SummaryRowItem label="NR" tooltip="Number of bonds with NR rating." value={summary?.nr?.toLocaleString()} />
                </SummaryRowGroup>
            </RequiredFeature>
        </SummaryRow>
    );
}